import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import {StaticQuery, graphql} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";

const Page = () => {
  return (
    <StaticQuery
      query={graphql`
        {
    allMarkdownRemark(limit: 1000, 
    filter: { fileAbsolutePath: {regex: "/(src/games)/"  }}
    sort: {fields: frontmatter___rating, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
              id
              title
              minPlayer
              maxPlayer
              rating
          }
        }
      }
    }
  }
      `}
      render={data => {
        let gameList = data.allMarkdownRemark.edges;

        return (
          <>
            <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url('/img/spiele/spiele-zu-zweit-title.jpg')`,
              }}
            >
              <h1
                className="has-text-weight-bold is-size-1"
                style={{
                  boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
                  backgroundColor: "#f40",
                  color: "white",
                  padding: "1rem",
                }}
              >
                Alle Spiele
              </h1>
            </div>
            <section className="section">
              <div className="container">
                <div className="content">
                  <div className="container content" style={{maxWidth: "1024px"}}>
                    <div className="columns">
                      <div className="column is-10 is-offset-1">
                        <div className="columns">
                          <div className="mb-5 mt-4 column">
                            <div className="pt-4">
                              <table className="table mt-4">
                                <thead>
                                <th>Bewertung</th>
                                <th>Spieleranzahl</th>
                                <th>Name</th>
                                </thead>
                                <tbody>
                                {
                                  gameList.map((data) => {
                                    let {
                                      id, maxPlayer, minPlayer, rating, title
                                    } = data.node.frontmatter;
                                    console.log(id, title)
                                    return (

                                      <tr>
                                        <td>{rating}</td>
                                        <td>{minPlayer ?? "?"}-{maxPlayer ?? "?"}</td>
                                        <td><a href={"/spiel/" + id + "/"} >{title}</a></td>
                                      </tr>
                                    )
                                  })
                                }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        );
      }}
    />

  );
}


export default function () {
  return (
    <Layout>
      <Page/>
    </Layout>
  )
}